import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout/layout';
import ArticleForm from './ArticleForm';
import ArticlePosts from './ArticlePosts';
import FeaturedCategories from './FeaturedCategories';
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import './Phone.scss'
import { BatteryIcon, CamComp, CameraIcon, ChargeComp, DesignIcon, DiscountIcon, DisplayIcon, HardwareIcon, HWComp, MedalIcon, MultiMediaIcon, OSComp, ReleaseIcon, ScreenComp, StorageComp } from '../svg/icons';
// import PopularPost from '../components/PopularPost/PopularPost';
// import LatestStories from '../components/PopularPost/LatestStories';
import { AiOutlineShoppingCart } from '@react-icons/all-files/ai/AiOutlineShoppingCart';
import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from 'gatsby';
import loadable from '@loadable/component'
import Seo from '../components/Seo/seo';
const PopularPost = loadable(() => import('../components/PopularPost/PopularPost'))
const LatestStories = loadable(() => import('../components/PopularPost/LatestStories'))

const Phone = ({ pageContext }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    const [thumbsSwiper2, setThumbsSwiper2] = useState<any>(null);
    const { data } = pageContext;

    const [showModal, setShowModal] = useState(false)

    const { info, battery, camera, design, display, hardware, image, multimedia, title, specs } = data;

    const date = new Date(specs.released)
        .toDateString()
        .split(" ")
        .slice(1, 4)
        .toString()
        .replace(",", " ")

    useEffect(() => {
        if (document.getElementById('phn-slider') !== null) {
            if (showModal === true) {
                (document.getElementById('phn-slider') as HTMLElement).style.display = "block";
            }
            else {
                (document.getElementById('phn-slider') as HTMLElement).style.display = 'none';
            }
        }
    })
    return (
        <Layout>
            <Seo title={title}
                description={info}
            />
            <div id='phn-slider' className='container py-5' >
                <span className='close' style={{ float: 'right' }} onClick={() => setShowModal(false)}><IoCloseOutline /></span>
                <Swiper
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                >
                    <SwiperSlide>
                        <GatsbyImage
                            image={
                                image[0]?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-banner"
                            className='activeSlider'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GatsbyImage
                            image={
                                image[1]?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-banner"
                            className='activeSlider'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GatsbyImage
                            image={
                                image[2]?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-banner"
                            className='activeSlider'
                        />
                    </SwiperSlide>
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <GatsbyImage
                            image={
                                image[0]?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-banner"
                            className='thumbSlider'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GatsbyImage
                            image={
                                image[1]?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-banner"
                            className='thumbSlider'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GatsbyImage
                            image={
                                image[2]?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-banner"
                            className='thumbSlider'
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="specs-page py-5 px-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 block-size">
                            <div className="specs-content">
                                <h1
                                    className="pt-1 pb-2"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                ></h1>
                            </div>
                            <div className='px-3 mb-3' style={{ border: '1px solid #e0e0e0', borderRadius: '5px' }}>
                                <div className='row pt-4' >
                                    <div className='col-md-4' style={{ cursor: 'pointer' }}>
                                        <Swiper
                                            loop={true}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{ swiper: thumbsSwiper2 }}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="mySwiper4"
                                            onClick={() => setShowModal(true)}
                                        >
                                            <SwiperSlide>
                                                <GatsbyImage
                                                    image={
                                                        image[0]?.localFile?.childImageSharp?.gatsbyImageData
                                                    }
                                                    loading="eager"
                                                    alt="specs-banner"
                                                    className='activeSlider2'
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <GatsbyImage
                                                    image={
                                                        image[1]?.localFile?.childImageSharp?.gatsbyImageData
                                                    }
                                                    loading="eager"
                                                    alt="specs-banner"
                                                    className='activeSlider2'
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <GatsbyImage
                                                    image={
                                                        image[2]?.localFile?.childImageSharp?.gatsbyImageData
                                                    }
                                                    loading="eager"
                                                    alt="specs-banner"
                                                    className='activeSlider2'
                                                />
                                            </SwiperSlide>
                                        </Swiper>
                                        <Swiper
                                            onSwiper={setThumbsSwiper2}
                                            loop={true}
                                            spaceBetween={10}
                                            slidesPerView={3}
                                            freeMode={true}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="mySwiper3"
                                        >
                                            <SwiperSlide>
                                                <GatsbyImage
                                                    image={
                                                        image[0]?.localFile?.childImageSharp?.gatsbyImageData
                                                    }
                                                    loading="eager"
                                                    alt="specs-banner"
                                                    className='thumbSlider2'

                                                />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <GatsbyImage
                                                    image={
                                                        image[1]?.localFile?.childImageSharp?.gatsbyImageData
                                                    }
                                                    loading="eager"
                                                    alt="specs-banner"
                                                    className='thumbSlider2'
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <GatsbyImage
                                                    image={
                                                        image[2]?.localFile?.childImageSharp?.gatsbyImageData
                                                    }
                                                    loading="eager"
                                                    alt="specs-banner"
                                                    className='thumbSlider2'
                                                />
                                            </SwiperSlide>
                                        </Swiper>

                                    </div>
                                    <div className='col-md-4 comp'>
                                        <p>
                                            <span className='col-md-2'><ReleaseIcon /></span>
                                            <span className='col-md-10'>
                                                <h3 className='release-date'>Release</h3>
                                                <p>{date}</p>
                                            </span>
                                        </p>

                                        <p>
                                            <span className='col-md-2'><CamComp /></span>
                                            <span className='col-md-10'>
                                                <ReactMarkdown>
                                                    {specs.Camera.data.Camera}
                                                </ReactMarkdown>
                                            </span>
                                        </p>
                                        <p>
                                            <span className='col-md-2'><HWComp /></span>
                                            <span className='col-md-10'>
                                                <ReactMarkdown>
                                                    {specs.Hardware.data.Hardware}
                                                </ReactMarkdown>
                                            </span>
                                        </p>
                                        <p>
                                            <span className='col-md-2'><ScreenComp /></span>
                                            <span className='col-md-10'>
                                                <ReactMarkdown>
                                                    {specs.Display.data.Display}
                                                </ReactMarkdown>
                                            </span>
                                        </p>
                                    </div>
                                    <div className='col-md-4 comp'>
                                        <p>
                                            <span className='col-md-2'><StorageComp /></span>
                                            <span className='col-md-10'>
                                                <ReactMarkdown>
                                                    {specs.Storage.data.Storage}
                                                </ReactMarkdown>
                                            </span>
                                        </p>
                                        <p>
                                            <span className='col-md-2'><ChargeComp /></span>
                                            <span className='col-md-10'>
                                                <ReactMarkdown>
                                                    {specs.Battery.data.Battery}
                                                </ReactMarkdown>
                                            </span>
                                        </p>
                                        <p>
                                            <span className='col-md-2'><OSComp /></span>
                                            <span className='col-md-10'>
                                                <ReactMarkdown>
                                                    {specs.OS.data.OS}
                                                </ReactMarkdown>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className='row pt-4 info'>
                                    <div className='col-md-4 p-4'>
                                        <ReactMarkdown>
                                            {info.description.data.description}
                                        </ReactMarkdown>
                                    </div>
                                    <div className='col-md-4 p-4'>
                                        <ReactMarkdown>
                                            {info.pros.data.pros}
                                        </ReactMarkdown>
                                    </div>
                                    <div className='col-md-4 score pt-4'>
                                        <span className='d-flex align-items-center'>
                                            <h6 className=''>8</h6>
                                            <span className='s-count'>
                                                <label htmlFor="file">Phonera Score</label>
                                                <p><Link to='' >12 review</Link></p>
                                                <progress id="file" value="72" max="100"></progress>
                                            </span>
                                        </span>
                                        <span className='d-flex align-items-center'>
                                            <span style={{
                                                padding: '2px 8px',
                                                width: '32px',
                                                height: '31px',
                                                background: '#FF6B00',
                                                borderRadius: '8px'
                                            }}><MedalIcon /></span>
                                            <span className='s-count'>
                                                <label htmlFor="file">No user review yet</label>
                                                <p><Link to='' >12 review</Link></p>
                                                <progress id="file" value="0" max="100"></progress>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row py-4 deal' >
                                <h2 ><AiOutlineShoppingCart />Deals</h2>
                                <div className="deal-section row">
                                    {/* <div className='d-flex justify-content-center py-4'> */}
                                    <div className='deal-div col-md-4 d-flex justify-content-center align-items-center'>
                                        <span className='span-1 d-flex justify-content-center align-items-center'>
                                            <span><DiscountIcon /></span>
                                            <span className='text'>$900</span>
                                        </span>
                                        <span className='span-2 d-flex justify-content-center align-items-center'>
                                            <StaticImage
                                                src="../images/Best_Buy.png"
                                                alt="Page Not Found"
                                                objectFit="cover"
                                                loading="eager"
                                                className="subscribe-popup-img"
                                            />
                                        </span>
                                    </div>
                                    <div className='deal-div col-md-4 d-flex justify-content-center align-items-center'>
                                        <span className='span-1 d-flex justify-content-center align-items-center'>
                                            <span><DiscountIcon /></span>
                                            <span className='text'>$1000</span>
                                        </span>
                                        <span className='span-2 d-flex justify-content-center align-items-center'>
                                            <StaticImage
                                                src="../images/Amazon_logo.png"
                                                alt="Page Not Found"
                                                objectFit="cover"
                                                loading="eager"
                                                className="subscribe-popup-img"
                                            />
                                        </span>
                                    </div>
                                    <div className='deal-div col-md-4 d-flex justify-content-center align-items-center'>
                                        <span className='span-1 d-flex justify-content-center align-items-center'>
                                            <span><DiscountIcon /></span>
                                            <span className='text'>$900</span>
                                        </span>
                                        <span className='span-2 d-flex justify-content-center align-items-center'>
                                            <StaticImage
                                                src="../images/Best_Buy.png"
                                                alt="Page Not Found"
                                                objectFit="cover"
                                                loading="eager"
                                                className="subscribe-popup-img"
                                            />
                                        </span>
                                    </div>
                                    {/* </div> */}
                                    <div className='d-flex justify-content-center'>
                                        <button className='btn mt-4 btnoffer'>View more offers</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-lg-11">
                                    <div className=" px-lg-3 pt-2">
                                        <div className="paragraph">
                                            <div className='p-table row'>
                                                <BatteryIcon />
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {battery.data.battery}
                                                </ReactMarkdown>
                                            </div>
                                            <div className='p-table row'>
                                                <CameraIcon />
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {camera.data.camera}
                                                </ReactMarkdown>
                                            </div>
                                            <div className='p-table row'>
                                                <DesignIcon />
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {design.data.design}
                                                </ReactMarkdown>
                                            </div>
                                            <div className='p-table row'>
                                                <DisplayIcon />
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {display.data.display}
                                                </ReactMarkdown>
                                            </div>
                                            <div className='p-table row'>
                                                <HardwareIcon />
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {hardware.data.hardware}
                                                </ReactMarkdown>
                                            </div>
                                            <div className='p-table row'>
                                                <MultiMediaIcon />
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {multimedia.data.multimedia}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-lg-11">
                                    <div className=" px-lg-3 pt-2">
                                        <div className="paragraph">
                                            <PopularPost />
                                        </div>
                                        <div className="paragraph py-4">
                                            <LatestStories />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 px-lg-5 mt-lg-0 mt-md-5 ">
                            <div className="h-100">
                                <div className="d-flex flex-column flex-sm-row flex-lg-column set-sticky pt-md-5 pt-lg-0 ">
                                    <div>
                                        <ArticleForm />
                                    </div>
                                    <div className="pt-lg-3 pt-sm-0 col-lg-12 col-sm-6  scrolling-effect">
                                        <ArticlePosts />
                                        <FeaturedCategories />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <RelatedPosts /> */}
                </div>
            </div>
        </Layout >
    )
}

export default Phone